import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faLayerGroup } from "@fortawesome/free-solid-svg-icons";

import StorefrontItem from "../partials/item";

import StorefrontError from "../partials/error";
import StorefrontLoading from "../partials/loading";
import { API_URL, LOGO_URL } from "../../../config";

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

const Index = () => {
    const [announcement, setAnnouncement] = useState(null);
    const [productCategories, setProductCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [currentPage, setCurrentPageF] = useState(0);
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [visibleProductCategoryChunks, setVisibleProductCategoryChunks] = useState([]);

    useEffect(() => {
        const fetchData = async() => {
            try {
                const data = await fetch(`${API_URL}/products`, {
                    method: "GET",
                    credentials: "include"
                }).then(r => r.json());
                if(!data.success) return setError(data.response);
                
                // setProductCategoryChunks(sliceIntoChunks(data.product_categories, 4));
                setProductCategories(data.product_categories);
                setVisibleProductCategoryChunks(sliceIntoChunks(data.product_categories.slice(0, 20), 4));
                setTags(data.tags);

                if(data.announcement.published === true && data.announcement.content !== null) {
                    console.log(data.announcement);
                    setAnnouncement(data.announcement.content);
                };

                setLoaded(true);
            } catch(e) {
                setError("An unknown error occurred, please try again later or contact support if this error persists")
            };
        };
        fetchData();
    }, []);

    // useEffect(() => {
    //     setVisibleProductCategoryChunks(productCategoryChunks);
    // }, [productCategoryChunks]);

    const filterProducts = (search) => {
        if(!search.length > 0) return setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.slice(20 * currentPage, (20 * currentPage) + 20), 4));
        setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.filter(pc => pc.category_name.toLowerCase().includes(search.toLowerCase())), 4));
    };

    const filterProductsByTag = (tag_id) => {
        if(tag_id === "all") return setVisibleProductCategoryChunks(sliceIntoChunks(productCategories, 4));
        setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.filter(pc => pc.category_tags.findIndex(t => t.tag_id === tag_id) !== -1), 4));
    };

    const setCurrentPage = (page) => {
        console.log(page, 20 * page);
        setVisibleProductCategoryChunks(sliceIntoChunks(productCategories.slice(20 * page, (20 * page) + 20), 4));
        setCurrentPageF(page);
    };

    if(error !== null) return <StorefrontError error={error} />;
    if(loaded === false) return (
        <div className="storefront-index">
			<StorefrontLoading />
            <div className="storefront-items" style={{ marginTop: "128px" }}></div>
        </div>
    );

    return (
        <div className="storefront-index">		
            {announcement !== null ? <div className="storefront-cont"><div className="storefront-announcement">{announcement}</div></div> : null}

            <div className="storefront-index-hero">
                <img className="storefront-index-hero-img" src={"https://i.imgur.com/L07nm8N.png"} alt="Site logo" />
            </div>
            <div className="storefront-index-search">
                <div className="storefront-search-container">
                    <div className="storefront-input-container">
                        <div className="storefront-input-icon"><FontAwesomeIcon icon={faMagnifyingGlass} /></div>
                        <input className="storefront-input" placeholder="Search for a product" onChange={(e) => filterProducts(e.target.value)} />
                    </div>

                    <div className="storefront-input-container">
                        <div className="storefront-input-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="pf-theme">
                                <path d="M7.55675 2.68997C6.4205 2.56298 5.2463 2.56298 4.11006 2.68997C3.37907 2.77167 2.78799 3.3473 2.70178 4.08433C2.56588 5.24634 2.56588 6.42024 2.70178 7.58225C2.78799 8.31928 3.37907 8.89491 4.11006 8.97661C5.2463 9.1036 6.4205 9.1036 7.55675 8.97661C8.28773 8.89491 8.87882 8.31928 8.96502 7.58225C9.10093 6.42024 9.10093 5.24634 8.96502 4.08433C8.87882 3.3473 8.28773 2.77167 7.55675 2.68997Z"></path>
                                <path d="M7.55675 11.0233C6.4205 10.8963 5.2463 10.8963 4.11006 11.0233C3.37907 11.105 2.78799 11.6806 2.70178 12.4177C2.56588 13.5797 2.56588 14.7536 2.70178 15.9156C2.78799 16.6526 3.37907 17.2282 4.11006 17.3099C5.2463 17.4369 6.4205 17.4369 7.55675 17.3099C8.28773 17.2282 8.87882 16.6526 8.96502 15.9156C9.10093 14.7536 9.10093 13.5797 8.96502 12.4177C8.87882 11.6806 8.28773 11.105 7.55675 11.0233Z"></path>
                                <path d="M15.8901 2.68997C14.7538 2.56298 13.5796 2.56298 12.4434 2.68997C11.7124 2.77167 11.1213 3.3473 11.0351 4.08433C10.8992 5.24634 10.8992 6.42024 11.0351 7.58225C11.1213 8.31928 11.7124 8.89491 12.4434 8.97661C13.5796 9.1036 14.7538 9.1036 15.8901 8.97661C16.6211 8.89491 17.2122 8.31928 17.2984 7.58225C17.4343 6.42024 17.4343 5.24634 17.2984 4.08433C17.2122 3.3473 16.6211 2.77167 15.8901 2.68997Z"></path>
                                <path d="M15.8901 11.0233C14.7538 10.8963 13.5796 10.8963 12.4434 11.0233C11.7124 11.105 11.1213 11.6806 11.0351 12.4177C10.8992 13.5797 10.8992 14.7536 11.0351 15.9156C11.1213 16.6526 11.7124 17.2282 12.4434 17.3099C13.5796 17.4369 14.7538 17.4369 15.8901 17.3099C16.6211 17.2282 17.2122 16.6526 17.2984 15.9156C17.4343 14.7536 17.4343 13.5797 17.2984 12.4177C17.2122 11.6806 16.6211 11.105 15.8901 11.0233Z"></path>
                            </svg>                  
                        </div>
                        <select className="select storefront-input" onChange={(e) => filterProductsByTag(e.target.value)}>
                            <option value="all">All Categories</option>
                            {tags.map((tag, index) => (
                                <option value={tag.tag_id} key={`product-tag-option-${tag.tag_id}-${index}`}>{tag.name}</option>
                            ))}
                        </select>
                    </div>
                    

                </div>
            </div>

            <div className="storefront-items">
                {visibleProductCategoryChunks.map((product_categories_chunk, index) => {
                    return (
                        <div className="storefront-items-row" key={`product-category-chunk-${index}`}>
                            {product_categories_chunk.map((product_category, pIndex) => {
                                return (
                                    <StorefrontItem key={`storefront-item-category-${index}-${pIndex}`} name={product_category.category_name} description={product_category.category_description} image={product_category.category_image_url} tags={product_category.category_tags} products={product_category.products} />
                                )
                            })}
                        </div>
                    )
                })}
            </div>

            <div className="storefront-pages">
                <div className="storefront-pagnation-buttons">
                    {currentPage > 0 ? <button className={`btn btn-pag`} onClick={() => setCurrentPage(currentPage - 1)}>prev</button> : null}
                    {Array(Math.ceil(productCategories.length / 20)).fill(1).map((page, index) => {
                        return <button key={`storefront-page-btn-${index}`} className={`btn btn-pag ${currentPage === index ? "btn-pag-active" : ""}`} onClick={() => setCurrentPage(index)}>{index + 1}</button>
                    })}
                    {currentPage < (productCategories.length / 20) - 1 ? <button className={`btn btn-pag`} onClick={() => setCurrentPage(currentPage + 1)}>next</button> : null}
                </div>
            </div>
        </div>
    )
};

export default Index;